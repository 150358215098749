html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-size: calc(10px + 1vmin);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

/* PRELOADER CSS */
.page-loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  /* background: #272727; */
  z-index: 1000;
}

.loader-initial {
  position: initial;
}

.page-loader .txt {
  color: #666;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}

/* SPINNER ANIMATION */
.spinner {
  position: relative;
  top: 35%;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-color: #272727;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.success {
  height: 100%;
  width: 100%;
}

.success .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  max-width: 1200px;
  height: 100%;
  /* padding-top: 200px; */
}

.success img {
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.success .wrapper .text {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 430;
  color: #272727;
  margin-bottom: 15px;
}

.success .wrapper .text span {
  color: #d10099;
  font-weight: 500;
}

.layour-wrapper {
  height: 100vh;
  margin-left: 300px;
}

@media (max-width: 480px) {
  .layour-wrapper {
    margin-left: 0px;
    margin-top: 50px;
    width: 100vw;
  }

  .success .wrapper {
    width: auto;
  }

  .success .wrapper .text {
    font-size: 16px;
  }

  .success .wrapper .text {
    padding: 0 20px;
  }
}


