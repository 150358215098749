.step-item {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}

.step-item .logo {
  padding: 8px 12px;
  border-radius: 50%;
  /* background-color: #d7f0fc; */
  margin-right: 10px;
  width: auto;
  font-size: 14px;
  font-weight: 600;
}

.step-item .content {
  display: flex;
  flex-direction: column;
  margin-top: -px;
}

.step-item .content .title {
  font-size: 14px;
  font-weight: 600;
  color: #939393;
  text-transform: uppercase;
}

.step-item .content .text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  color: #171717;
}
