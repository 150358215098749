.help-container {
  border-radius: 10px;
  padding: 15px;
  background-color: #fffbf5;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.help-container .logo-help img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  color: aliceblue;
}

.help-container .content {
  display: flex;
  flex-direction: column;
}
.help-container .content .title {
  font-size: 14px;
  color: #151515;
  margin-bottom: 5px;
}

.help-container .content .text {
  font-size: 12px;
  color: #7f7f7f;
  line-height: 1.2;
  text-align: justify;
}

.help-container .content .phone {
  font-size: 12px;
  font-weight: 600;
  color: #151515;
  margin-top: 10px;
}

.email {
  font-size: 12px;
  color: #4d12f9;
  margin-top: 5px;
}
