.privacy-container {
  margin-top: 10px;
}

.privacy-container .text {
  font-size: 12px;
  color: #525252;
  line-height: 1.2;
  text-align: justify;
}

.privacy-container .privacy {
  margin-top: 20px;
  font-weight: 600;
  font-size: 10px;

  color: #424242;
}
