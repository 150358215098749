.sidebar-header-mobile {
  display: none;
  justify-content: space-between;
  position: fixed;
  top: 0;
  padding: 0.625rem 1.6rem;
  background-color: #fff;
  width: 100%;
  z-index: 10;
}

.sidebar-header-mobile .logo img {
  height: 26px;
  width: 130px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.sidebar-header-mobile .logo-eloan img {
  height: 56px;
  width: 100px;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.sidebar-header-mobile .logo-crediterium img {
  height: 26px;
  width: 152px;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.sidebar-header-mobile .logo-crediterium div {
  margin-top: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #272727;
}

.sidebar-header-mobile .help-section {
  display: flex;
  margin-top: 25px;
  padding: 3px 7px;
  background-color: #f2f2f2;
  color: #4c0ff9;
  font-size: 10px;
  height: 15px;
  font-weight: 500;
  border-radius: 10px;
  margin-right: 55px;
  cursor: pointer;
}

.sidebar-header-mobile .help-section img {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-top: 0px;
}

@media (max-width: 480px) {
  .sidebar-header-mobile {
    display: flex;
  }
}
