.sidebar-container {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  background-color: #fff;
  overflow-x: hidden;
  box-shadow: 0 2px 5px #d7f0fc;
  padding: 30px 25px;
  z-index: 12;
  transition: 0.3s;
}
.sidebar-container .icon-x {
  position: fixed;
  display: none;
  top: 20px;
  right: 50px;
  font-size: 20px;
  color: #4c0ff9;
  cursor: pointer;
}

.sidebar-container .icon-x img {
  width: 20px;
  height: 20px;
}

.sidebar-container .logo img {
  height: 26px;
  width: 130px;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.sidebar-container .logo-eloan img {
  height: 56px;
  width: 100px;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.sidebar-container .logo-crediterium img {
  height: 26px;
  width: 152px;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.sidebar-container .logo-clearline img {
  
  width: 152px;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.sidebar-container .language-list {
  display: flex;
  margin-top: 20px;
}

.sidebar-container .language-list .item {
  margin-right: 10px;
  font-size: 10px;
  font-weight: 600;
  color: #7f7f7f;
  cursor: pointer;
}

.sidebar-container .language-list .active {
  color: #4d12f9;
}

.sidebar-container .sidebar-content {
  margin-top: 15px;
}

.sidebar-container .sidebar-content .title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.sidebar-container .sidebar-content .text {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #525252;
  text-align: justify;
}

.sidebar-container .step-list {
  margin-top: 20px;
}

@media (max-width: 480px) {
  .sidebar-container {
    padding-top: 8px;
  }

  .sidebar-container .icon-x {
    display: block;
  }
}
